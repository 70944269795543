import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';

import ExploreTab from '../ExploreTab';
import { PROMT_LANDING_PAGE } from '../../constans/texts/texts';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { PHONE_WIDTH_BREAKPOINT } from '../../constans/header';

import styles from './index.module.scss';

const DESCKTOP_TIME = 10000;

const Home = () => {
  const [promtText, setPromtText] = useState(PROMT_LANDING_PAGE[0]);
  const windowWidth = useWindowWidth();

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * PROMT_LANDING_PAGE.length);
    setPromtText(PROMT_LANDING_PAGE[index]);
  }, []);

  const isMobile = useMemo(() => windowWidth <= PHONE_WIDTH_BREAKPOINT, [windowWidth]);

  useEffect(() => {
    if (!isMobile) {
      const intervalID = setInterval(shuffle, DESCKTOP_TIME);
      return () => clearInterval(intervalID);
    }
  }, [shuffle, isMobile]);

  return (
    <div className={`${styles.home} flex-grow-1`}>
      <div className={`${styles.container} landing-container flex flex-center`}>
        <div className="col-12 col-lg-10 pl-0">
          {!isMobile && (
          <div className={`${styles.promtBlock}`}>
            <div className={`${styles.promtText}`}>
              {promtText}
            </div>
          </div>
          )}
          {isMobile && (
          <div>
            <marquee className={`${styles.dynamicText}`}>
              {
              `${PROMT_LANDING_PAGE[0]}`
              + ' \xA0 \xA0 \xA0 \xA0 \xA0 \xA0  \xA0 \xA0 \xA0 '
              + `${PROMT_LANDING_PAGE[1]}`
              + ' \xA0 \xA0 \xA0 \xA0 \xA0 \xA0  \xA0 \xA0 \xA0 '
            }
              {' '}

            </marquee>
          </div>
          )}
          <div className={styles.homeContent}>
            <ExploreTab styles={styles} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
